<section class="workers directionRitght">
    <div class="container">
        <div class="headerSection">
            <h1>{{ 'Workers' | translate }}
                <a class="btn-next" (click)="next()" style="position: absolute;">
                    <span class="next-worker">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="26.317" viewBox="0 0 15.001 26.317">
                            <g id="arrow" transform="translate(15.001) rotate(90)">
                              <path id="Path_11132" data-name="Path 11132" d="M13.158,0a1.838,1.838,0,0,0-1.3.54L.54,11.855a1.843,1.843,0,0,0,2.606,2.606L13.158,4.449,23.171,14.461a1.843,1.843,0,1,0,2.606-2.606L14.461.539A1.838,1.838,0,0,0,13.158,0Z" transform="translate(0)" fill="#fff"/>
                            </g>
                          </svg>
                          
                    </span>
                </a>
                <a class="btn-prev" (click)="prev()">
                    <span class="prev-worker">
                        <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="26.317" viewBox="0 0 15.001 26.317">
                            <g id="arrow" transform="translate(15.001) rotate(90)">
                              <path id="Path_11132" data-name="Path 11132" d="M13.158,15a1.838,1.838,0,0,1-1.3-.54L.54,3.146A1.843,1.843,0,1,1,3.146.54L13.158,10.552,23.171.54a1.843,1.843,0,1,1,2.606,2.606L14.461,14.462A1.838,1.838,0,0,1,13.158,15Z" transform="translate(0)" fill="#fff"/>
                            </g>
                          </svg>
                    </span>
                </a>
            </h1>
        </div>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfigWorcks" >
            <div ngxSlickItem  class="slide carouselItem " *ngFor="let item of cats">
                <div class="itemslider">
                    <div class="item-worker">
                        <img class="img-fluid" src="{{item.image}}">
                        <p>{{item.name}}</p>
                    </div>
                </div>
            </div>  
        </ngx-slick-carousel>
    </div>
</section>