<section class="workers customer">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="header-asked"> {{ 'Customeropinionsaboutourservices' | translate }}</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="customer-card">
                    <div class="custom-img">
                        <img src="../../../../assets/image/img/customer1.png">
                    </div>
                    <h4>Ahmed Mohamed Ali</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="customer-card">
                    <div class="custom-img">
                        <img src="../../../../assets/image/img/customer2.png">
                    </div>
                    <h4>Ahmed Mohamed Ali</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="customer-card">
                    <div class="custom-img">
                        <img src="../../../../assets/image/img/customer3.png">
                    </div>
                    <h4>Ahmed Mohamed Ali</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
                </div>
            </div>
            <div class="col-md-3">
                <div class="customer-card">
                    <div class="custom-img">
                        <img src="../../../../assets/image/img/customer1.png">
                    </div>
                    <h4>Ahmed Mohamed Ali</h4>
                    <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor</p>
                </div>
            </div>
        </div>
        
    </div>
</section>