<div id="carouselExampleCaptions" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleCaptions" data-slide-to="i" [ngClass]="{'active': i==0}" *ngFor="let item of slider; let i = index"></li>
    </ol>
    <div class="carousel-inner">
      <div class="carousel-item"  [ngClass]="{'active': i==0}" *ngFor="let item of slider; let i = index">
          <div class="overFlowSlider">
            <div class="carouselCaption ">
                <h5>{{item.text}}</h5>
            </div>
          </div>
        <img src="{{item.image}}" class="d-block w-100" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-target="#carouselExampleCaptions" data-slide="prev">
      <span class="carousel-prev">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="26.317" viewBox="0 0 15.001 26.317">
          <g id="arrow" transform="translate(15.001) rotate(90)">
            <path id="Path_11132" data-name="Path 11132" d="M13.158,15a1.838,1.838,0,0,1-1.3-.54L.54,3.146A1.843,1.843,0,1,1,3.146.54L13.158,10.552,23.171.54a1.843,1.843,0,1,1,2.606,2.606L14.461,14.462A1.838,1.838,0,0,1,13.158,15Z" transform="translate(0)" fill="#6c3a79"/>
          </g>
        </svg>
      </span>
      
    </button>
    <button class="carousel-control-next" type="button" data-target="#carouselExampleCaptions" data-slide="next">
      <span class="carousel-next">
        <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="26.317" viewBox="0 0 15.001 26.317">
          <g id="arrow" transform="translate(15.001) rotate(90)">
            <path id="Path_11132" data-name="Path 11132" d="M13.158,0a1.838,1.838,0,0,0-1.3.54L.54,11.855a1.843,1.843,0,0,0,2.606,2.606L13.158,4.449,23.171,14.461a1.843,1.843,0,1,0,2.606-2.606L14.461.539A1.838,1.838,0,0,0,13.158,0Z" transform="translate(0)" fill="#6c3a79"/>
          </g>
        </svg>        
        <!-- <i class='fas fa-angle-right'></i> -->
      </span>
    </button>
  </div>