<nav class="navbar navbar-expand-lg navbar-light headerHome headerPages">
    
  <div class="container">
      <a class="navbar-brand logo-mobile">
          <img routerLink="/home" src="../../../../assets/image/logo.png">
      </a>
      <div class="navbar-brand logo-web">
          <a  class="logo">
              <img routerLink="/home" src="../../../../assets/image/logo.png">
          </a>
          <div class="btn-group">
            <a class="dropdown-toggle language flagLang" type="button" data-toggle="dropdown" aria-expanded="false">
                <svg *ngIf="flag" xmlns="http://www.w3.org/2000/svg" width="23.9" height="15.659" viewBox="0 0 23.9 15.659">
                    <g id="saudi-arabia" transform="translate(0 -88.276)">
                      <path id="Path_11560" data-name="Path 11560" d="M23.488,103.935H.412A.412.412,0,0,1,0,103.523V88.688a.412.412,0,0,1,.412-.412H23.488a.412.412,0,0,1,.412.412v14.834A.412.412,0,0,1,23.488,103.935Z" fill="#73af00"/>
                      <g id="Group_1818" data-name="Group 1818" transform="translate(4.725 91.573)">
                        <path id="Path_11561" data-name="Path 11561" d="M102.375,167.76c.041.26.2.342.335.331a.336.336,0,0,0,.3-.316v-.893a.081.081,0,1,1,.162,0v.9a.325.325,0,0,0,.446.3l.081-.032c.007.75-.038,1.409-.039,1.421a.162.162,0,0,0,.15.174h.012a.162.162,0,0,0,.162-.151c0-.053.054-.775.039-1.574l.049-.02a.163.163,0,0,1,.133.006.464.464,0,0,0,.536-.087l.029-.029a.485.485,0,0,0,.118-.5l-.138-.415a.162.162,0,0,0,.12-.3l-.325-.162a.162.162,0,0,0-.227.2l.261.784a.162.162,0,0,1-.039.166l-.029.029a.14.14,0,0,1-.161.026.486.486,0,0,0-.338-.034,5.6,5.6,0,0,0-.2-1.4.162.162,0,0,0-.308.1,5.584,5.584,0,0,1,.186,1.418l-.194.078v-.9a.406.406,0,0,0-.812,0v.786l-.166-.819a.162.162,0,1,0-.318.064Z" transform="translate(-102.155 -165.735)" fill="#f5f5f5"/>
                        <path id="Path_11562" data-name="Path 11562" d="M109.322,163.081a.162.162,0,0,0,.162-.162v-.162a.162.162,0,1,0-.325,0v.162A.162.162,0,0,0,109.322,163.081Z" transform="translate(-108.79 -162.422)" fill="#f5f5f5"/>
                        <path id="Path_11563" data-name="Path 11563" d="M144.119,162.919h.162a.162.162,0,1,0,0-.325h-.162a.162.162,0,1,0,0,.325Z" transform="translate(-141.962 -162.422)" fill="#f5f5f5"/>
                        <path id="Path_11564" data-name="Path 11564" d="M106.57,214.813l-.812.487a.162.162,0,1,0,.167.279l.812-.487a.162.162,0,1,0-.167-.279Z" transform="translate(-105.471 -212.181)" fill="#f5f5f5"/>
                        <path id="Path_11565" data-name="Path 11565" d="M118.638,168.658l0,0a.371.371,0,0,0,.075.153.319.319,0,0,0,.277.108.448.448,0,0,0,.338-.178.293.293,0,0,0,.034.02.307.307,0,0,0,.286-.009.617.617,0,0,0,.291-.572.166.166,0,0,0-.169-.154.163.163,0,0,0-.155.169s0,.195-.114.266a.4.4,0,0,1-.051-.137.162.162,0,0,0-.323.027c0,.024-.005.232-.166.25a.4.4,0,0,1,0-.213.162.162,0,0,0-.249-.172l-.091.062c-.043-.942-.142-2.04-.143-2.054a.162.162,0,1,0-.324.03c0,.015.116,1.285.151,2.255l-.025.024a.419.419,0,0,0-.356-.279.593.593,0,0,0-.572.362.384.384,0,0,0,.124.484.541.541,0,0,0,.484.022,1.358,1.358,0,0,1-.264.5.682.682,0,0,1-.519.264.427.427,0,0,1-.315-.135.928.928,0,0,1-.172-.581.162.162,0,0,0-.153-.171.159.159,0,0,0-.171.153,1.246,1.246,0,0,0,.255.816.745.745,0,0,0,.544.241h.033a.99.99,0,0,0,.745-.378,1.68,1.68,0,0,0,.372-.833,1.841,1.841,0,0,1-.779,1.459.811.811,0,0,1-.814.059.7.7,0,0,1-.357-.687.162.162,0,0,0-.322-.04,1.02,1.02,0,0,0,.536,1.019,1.1,1.1,0,0,0,.492.117,1.2,1.2,0,0,0,.637-.192,2.16,2.16,0,0,0,.933-1.736C118.643,168.892,118.641,168.778,118.638,168.658Zm-.979.167c-.029-.019-.006-.081,0-.093a.276.276,0,0,1,.227-.16h.013c.021,0,.076.007.1.13,0,.013,0,.026.006.04C117.822,168.849,117.7,168.853,117.658,168.825Z" transform="translate(-115.349 -165.738)" fill="#f5f5f5"/>
                        <path id="Path_11566" data-name="Path 11566" d="M101.84,243.306a.163.163,0,0,0-.222.058.334.334,0,0,1-.027.039l-.005-.013a1.093,1.093,0,0,1,0-.551.162.162,0,0,0-.314-.084,1.4,1.4,0,0,0,0,.749c.075.2.189.249.272.256h.022a.427.427,0,0,0,.324-.232A.162.162,0,0,0,101.84,243.306Z" transform="translate(-101.226 -238.725)" fill="#f5f5f5"/>
                        <path id="Path_11567" data-name="Path 11567" d="M175.292,164.057a.161.161,0,0,0,.1-.035l.819-.643a.156.156,0,0,0,.027-.031.407.407,0,1,0-.467-.036l-.58.455a.162.162,0,0,0,.1.29Zm.738-1.137a.081.081,0,1,1-.081.081A.081.081,0,0,1,176.03,162.92Z" transform="translate(-171.68 -162.422)" fill="#f5f5f5"/>
                        <path id="Path_11568" data-name="Path 11568" d="M185.389,190.441a.162.162,0,0,0-.206.1.5.5,0,0,0,.063.479.328.328,0,0,0,.243.093.714.714,0,0,0,.224-.041.162.162,0,1,0-.1-.308.563.563,0,0,1-.133.028.311.311,0,0,1,.013-.148A.162.162,0,0,0,185.389,190.441Z" transform="translate(-181.231 -188.96)" fill="#f5f5f5"/>
                        <path id="Path_11569" data-name="Path 11569" d="M227.733,163.091a.163.163,0,0,0,.2-.11l.095-.325a.162.162,0,0,0-.312-.091l-.095.325A.162.162,0,0,0,227.733,163.091Z" transform="translate(-221.716 -162.282)" fill="#f5f5f5"/>
                        <path id="Path_11570" data-name="Path 11570" d="M205.979,159.688a.162.162,0,1,0,.164-.28c-.033-.019-.028-.073-.027-.083a.127.127,0,0,1,.075-.108.162.162,0,0,0-.1-.31.446.446,0,0,0-.3.382A.4.4,0,0,0,205.979,159.688Z" transform="translate(-200.909 -158.898)" fill="#f5f5f5"/>
                        <path id="Path_11571" data-name="Path 11571" d="M178.916,232.19a.162.162,0,0,0-.03.322.305.305,0,0,1,.03.165.162.162,0,0,0,.325,0C179.241,232.2,178.93,232.19,178.916,232.19Z" transform="translate(-175.135 -228.769)" fill="#f5f5f5"/>
                        <path id="Path_11572" data-name="Path 11572" d="M234.75,208.294a.373.373,0,0,0,.061,0,.555.555,0,0,0,.384-.192.162.162,0,0,0-.23-.23c-.1.1-.149.1-.163.1-.031,0-.059-.045-.065-.056a.162.162,0,0,0-.29.147A.439.439,0,0,0,234.75,208.294Z" transform="translate(-228.213 -205.545)" fill="#f5f5f5"/>
                        <path id="Path_11573" data-name="Path 11573" d="M293.9,190.933l0-.007a.162.162,0,0,0-.308.1.364.364,0,0,0,.264.253l.036,0a.4.4,0,0,0,.314-.217.162.162,0,0,0-.27-.18A.5.5,0,0,1,293.9,190.933Z" transform="translate(-284.606 -189.326)" fill="#f5f5f5"/>
                        <path id="Path_11574" data-name="Path 11574" d="M204.018,170.29c0-.693-.03-1.342-.073-1.912a8.224,8.224,0,0,1,.57,1.157.162.162,0,1,0,.3-.114,10.757,10.757,0,0,0-.939-1.78c-.089-.875-.188-1.43-.19-1.44a.162.162,0,0,0-.32.058c0,.007.052.293.114.775-.125-.185-.241-.352-.338-.49l.008,0a.162.162,0,1,0,.1-.308l-.487-.162a.162.162,0,0,0-.183.059.164.164,0,0,0-.024.05.354.354,0,0,0-.339-.081.276.276,0,0,0-.155.289,1.468,1.468,0,0,0,.112.46c.1.271.156.47.054.565a.178.178,0,0,1-.166.059c-.1-.025-.2-.159-.265-.357a.152.152,0,0,0-.013-.023,2.762,2.762,0,0,0-.05-.9.162.162,0,0,0-.319.064,1.546,1.546,0,0,1-.085,1.158.292.292,0,0,1-.243.11.093.093,0,0,1-.078-.036,1.566,1.566,0,0,1-.086-.916.163.163,0,0,0-.161-.185c-.279,0-.385.318-.479.6-.041.123-.117.352-.171.376-.1,0-.162-.289-.162-.487a.162.162,0,0,0-.162-.162h0a.162.162,0,0,0-.162.162c0,.375.128.812.487.812.268,0,.376-.292.467-.563a1.018,1.018,0,0,0,.184.577.417.417,0,0,0,.323.148.618.618,0,0,0,.494-.229.747.747,0,0,0,.06-.09.606.606,0,0,0,.34.255.468.468,0,0,0,.115.015.515.515,0,0,0,.354-.151.482.482,0,0,0,.054-.066.439.439,0,0,0,.12.018.657.657,0,0,0,.337-.1.162.162,0,0,0-.174-.274.345.345,0,0,1-.184.048,1.676,1.676,0,0,0-.123-.534,2.252,2.252,0,0,1-.084-.272l.038.034a.162.162,0,0,0,.23,0,.157.157,0,0,0,.024-.036c.168.232.515.719.871,1.281.068.691.128,1.572.128,2.538a.162.162,0,0,0,.325,0Z" transform="translate(-195.038 -165.732)" fill="#f5f5f5"/>
                        <path id="Path_11575" data-name="Path 11575" d="M293.749,166.726a.162.162,0,1,0,.325,0,1.656,1.656,0,0,0-.18-.56.162.162,0,1,0-.291.145A1.443,1.443,0,0,1,293.749,166.726Z" transform="translate(-284.607 -165.741)" fill="#f5f5f5"/>
                        <path id="Path_11576" data-name="Path 11576" d="M263.156,249.931a.162.162,0,0,0-.218.072.465.465,0,0,1-.067.1.907.907,0,0,1,.043-.3.163.163,0,0,0-.27-.163l-.325.325a.162.162,0,1,0,.23.23l.008-.008a.336.336,0,0,0,.087.171.3.3,0,0,0,.211.093.289.289,0,0,0,.045,0,.529.529,0,0,0,.329-.294A.162.162,0,0,0,263.156,249.931Z" transform="translate(-254.753 -245.355)" fill="#f5f5f5"/>
                        <path id="Path_11577" data-name="Path 11577" d="M170.2,205.5h-1.145q.015-.061.031-.116c.457-.095.728-.242.808-.439a.363.363,0,0,0-.061-.365.4.4,0,0,0-.411-.175c-.231.047-.436.3-.584.694a1.868,1.868,0,0,0-.938.406,2.516,2.516,0,0,0-.337-1.08.162.162,0,0,0-.27.18,2.346,2.346,0,0,1,.3,1.209,1.285,1.285,0,0,1-.975,1.3c-.182,0-.262-.217-.3-.4a1.865,1.865,0,0,0,.78-.86,1.292,1.292,0,0,0-.108-.881l-.012-.028.01.01a.162.162,0,0,0,.23-.23c-.095-.1-.279-.244-.447-.174a.276.276,0,0,0-.155.289.691.691,0,0,0,.076.261.982.982,0,0,1,.092.674,1.374,1.374,0,0,1-.532.586.16.16,0,0,0-.123-.06h0a.162.162,0,0,0-.162.162c0,.029,0,.06,0,.093a8.541,8.541,0,0,1-1.045.572.162.162,0,0,0,.067.31.16.16,0,0,0,.067-.015,9.353,9.353,0,0,0,.97-.519.632.632,0,0,0,.587.534,1.38,1.38,0,0,0,1.087-.794.942.942,0,0,0,.108.155,1.831,1.831,0,0,0,1.241.477.162.162,0,0,0,.012-.324.348.348,0,0,1-.2-.1.279.279,0,0,1-.053-.213c.017-.2.662-.35,1.231-.359a.163.163,0,0,0,.151-.108l.162-.457a.163.163,0,0,0-.153-.217Zm-.713-.776.012,0c.016,0,.04.008.073.052.006.008.026.036.021.049-.019.046-.131.122-.364.191C169.333,204.809,169.434,204.731,169.489,204.72Zm-.981,2.135a1,1,0,0,1-.45-.273.691.691,0,0,1-.144-.572.477.477,0,0,1,.034-.069c.229-.021.84.069.882.244a.545.545,0,0,0-.343.424A.685.685,0,0,0,168.508,206.855Z" transform="translate(-161.862 -202.227)" fill="#f5f5f5"/>
                        <path id="Path_11578" data-name="Path 11578" d="M291.4,166.669l-.162-.487a.162.162,0,0,0-.316.068c.112,1.121.324,3.307.324,3.557,0,.225-.4.668-1.047.992a.162.162,0,0,0,.145.291c.591-.3,1.227-.823,1.227-1.282,0-.24-.166-1.967-.261-2.937A.163.163,0,0,0,291.4,166.669Z" transform="translate(-281.29 -165.736)" fill="#f5f5f5"/>
                        <path id="Path_11579" data-name="Path 11579" d="M335.881,194.018a.162.162,0,0,0,.272-.155.82.82,0,0,1-.023-.315.165.165,0,0,0,.169-.139.162.162,0,0,0-.138-.184.293.293,0,0,0-.268.09.372.372,0,0,0-.082.174l-.188-.188a.162.162,0,0,0-.23.23Z" transform="translate(-324.417 -191.619)" fill="#f5f5f5"/>
                        <path id="Path_11580" data-name="Path 11580" d="M340.806,213.295l-.609.437a.162.162,0,1,0,.189.264l.609-.437a.162.162,0,1,0-.189-.264Z" transform="translate(-328.977 -210.726)" fill="#f5f5f5"/>
                        <path id="Path_11581" data-name="Path 11581" d="M352.776,225.725a.162.162,0,0,0,.014.215.45.45,0,0,1,.033.234.162.162,0,0,0,.157.168h.006a.162.162,0,0,0,.162-.157c.012-.332-.1-.453-.144-.489A.162.162,0,0,0,352.776,225.725Z" transform="translate(-341.002 -222.546)" fill="#f5f5f5"/>
                        <path id="Path_11582" data-name="Path 11582" d="M324.335,170.117a.162.162,0,0,0,.162-.162c0-.24-.012-.507-.032-.786a5.925,5.925,0,0,1,.381.707.162.162,0,0,0,.149.1.16.16,0,0,0,.064-.013.162.162,0,0,0,.085-.213,8.935,8.935,0,0,0-.743-1.281c-.064-.6-.147-1.18-.212-1.6a.157.157,0,0,0,.1-.025.16.16,0,0,0,.072-.147.159.159,0,0,0,.018.047.591.591,0,0,1,.059.4.162.162,0,0,0,.126.192.171.171,0,0,0,.033,0,.163.163,0,0,0,.159-.13.921.921,0,0,0-.1-.638.163.163,0,0,0-.224-.05.161.161,0,0,0-.073.147.157.157,0,0,0-.018-.047l-.274-.426a.162.162,0,0,0-.3.116c0,.013.126.726.236,1.585-.125-.176-.249-.348-.37-.512-.02-.246-.039-.473-.056-.661l.026-.01a.162.162,0,0,0,.073-.218l-.162-.325a.162.162,0,0,0-.307.089c.019.194.04.408.059.634-.1-.135-.2-.259-.279-.364a.163.163,0,0,0-.033-.269l-.325-.162a.162.162,0,0,0-.2.247c.009.011.409.512.887,1.159a13.062,13.062,0,0,1,.06,2.424c-.014.043-.039.093-.063.1-.1.014-.333-.206-.45-.441a.226.226,0,0,0-.218-.147c-.175.011-.243.2-.366.552a2.287,2.287,0,0,1-.323.688,1.845,1.845,0,0,1-.2-.64.162.162,0,0,0,.314-.055c0-.242-.169-2.087-.264-3.1a.162.162,0,0,0,.093-.2l-.162-.487a.162.162,0,0,0-.316.067c.11,1.159.317,3.4.324,3.706v-.008a.162.162,0,1,0-.322.046c.047.332.169.9.448,1a.271.271,0,0,0,.087.014.319.319,0,0,0,.229-.106,2.343,2.343,0,0,0,.4-.812c.024-.067.052-.147.079-.215.149.218.409.489.672.467a.4.4,0,0,0,.348-.316A9.146,9.146,0,0,0,323.68,168c.137.192.274.388.4.582.051.486.087.974.087,1.374A.163.163,0,0,0,324.335,170.117Z" transform="translate(-311.142 -165.742)" fill="#f5f5f5"/>
                        <path id="Path_11583" data-name="Path 11583" d="M382.517,169.675c-.115-.857-.275-1.85-.377-2.46a.16.16,0,0,0,.136-.029.162.162,0,0,0,.031-.228l-.294-.386a.162.162,0,0,0-.289.126c0,.017.293,1.684.472,3.019a1.522,1.522,0,0,1-.267,1.219.718.718,0,0,1-.6.222.162.162,0,1,0-.02.324l.081,0a1.025,1.025,0,0,0,.773-.327A1.814,1.814,0,0,0,382.517,169.675Z" transform="translate(-368.092 -166.154)" fill="#f5f5f5"/>
                        <path id="Path_11584" data-name="Path 11584" d="M371.057,256.184a.165.165,0,0,0,.008.228.069.069,0,0,1,.019.034.076.076,0,0,1-.025.036.162.162,0,1,0,.23.23.369.369,0,0,0,.12-.277.387.387,0,0,0-.127-.265A.161.161,0,0,0,371.057,256.184Z" transform="translate(-358.419 -251.592)" fill="#f5f5f5"/>
                        <path id="Path_11585" data-name="Path 11585" d="M154.028,326.946h-1.137v-.162a.162.162,0,1,0-.325,0v.162h-8.609a2.268,2.268,0,0,0,1.462.487h7.147v.325a.162.162,0,0,0,.162.162h1.3a.487.487,0,1,0,0-.975Zm0,.65h-1.137v-.162h1.3l-.029-.087a.158.158,0,0,1,.029.087A.163.163,0,0,1,154.028,327.6Z" transform="translate(-141.962 -318.792)" fill="#f5f5f5"/>
                      </g>
                    </g>
                  </svg>     
                  <img class="flaghome" *ngIf="!flag" src="../../../../assets/image/Flag_of_England.svg.png">                  
                 {{titleLang}}
            </a>
            <div class="dropdown-menu">
              <a class="dropdown-item flagLang" (click)="langButton('en')">
                  <img class="flaghome" src="../../../../assets/image/Flag_of_England.svg.png">                      
                  English
              </a>
              <a class="dropdown-item flagLang" (click)="langButton('ar')">
                  <svg xmlns="http://www.w3.org/2000/svg" width="23.9" height="15.659" viewBox="0 0 23.9 15.659">
                      <g id="saudi-arabia" transform="translate(0 -88.276)">
                      <path id="Path_11560" data-name="Path 11560" d="M23.488,103.935H.412A.412.412,0,0,1,0,103.523V88.688a.412.412,0,0,1,.412-.412H23.488a.412.412,0,0,1,.412.412v14.834A.412.412,0,0,1,23.488,103.935Z" fill="#73af00"/>
                      <g id="Group_1818" data-name="Group 1818" transform="translate(4.725 91.573)">
                          <path id="Path_11561" data-name="Path 11561" d="M102.375,167.76c.041.26.2.342.335.331a.336.336,0,0,0,.3-.316v-.893a.081.081,0,1,1,.162,0v.9a.325.325,0,0,0,.446.3l.081-.032c.007.75-.038,1.409-.039,1.421a.162.162,0,0,0,.15.174h.012a.162.162,0,0,0,.162-.151c0-.053.054-.775.039-1.574l.049-.02a.163.163,0,0,1,.133.006.464.464,0,0,0,.536-.087l.029-.029a.485.485,0,0,0,.118-.5l-.138-.415a.162.162,0,0,0,.12-.3l-.325-.162a.162.162,0,0,0-.227.2l.261.784a.162.162,0,0,1-.039.166l-.029.029a.14.14,0,0,1-.161.026.486.486,0,0,0-.338-.034,5.6,5.6,0,0,0-.2-1.4.162.162,0,0,0-.308.1,5.584,5.584,0,0,1,.186,1.418l-.194.078v-.9a.406.406,0,0,0-.812,0v.786l-.166-.819a.162.162,0,1,0-.318.064Z" transform="translate(-102.155 -165.735)" fill="#f5f5f5"/>
                          <path id="Path_11562" data-name="Path 11562" d="M109.322,163.081a.162.162,0,0,0,.162-.162v-.162a.162.162,0,1,0-.325,0v.162A.162.162,0,0,0,109.322,163.081Z" transform="translate(-108.79 -162.422)" fill="#f5f5f5"/>
                          <path id="Path_11563" data-name="Path 11563" d="M144.119,162.919h.162a.162.162,0,1,0,0-.325h-.162a.162.162,0,1,0,0,.325Z" transform="translate(-141.962 -162.422)" fill="#f5f5f5"/>
                          <path id="Path_11564" data-name="Path 11564" d="M106.57,214.813l-.812.487a.162.162,0,1,0,.167.279l.812-.487a.162.162,0,1,0-.167-.279Z" transform="translate(-105.471 -212.181)" fill="#f5f5f5"/>
                          <path id="Path_11565" data-name="Path 11565" d="M118.638,168.658l0,0a.371.371,0,0,0,.075.153.319.319,0,0,0,.277.108.448.448,0,0,0,.338-.178.293.293,0,0,0,.034.02.307.307,0,0,0,.286-.009.617.617,0,0,0,.291-.572.166.166,0,0,0-.169-.154.163.163,0,0,0-.155.169s0,.195-.114.266a.4.4,0,0,1-.051-.137.162.162,0,0,0-.323.027c0,.024-.005.232-.166.25a.4.4,0,0,1,0-.213.162.162,0,0,0-.249-.172l-.091.062c-.043-.942-.142-2.04-.143-2.054a.162.162,0,1,0-.324.03c0,.015.116,1.285.151,2.255l-.025.024a.419.419,0,0,0-.356-.279.593.593,0,0,0-.572.362.384.384,0,0,0,.124.484.541.541,0,0,0,.484.022,1.358,1.358,0,0,1-.264.5.682.682,0,0,1-.519.264.427.427,0,0,1-.315-.135.928.928,0,0,1-.172-.581.162.162,0,0,0-.153-.171.159.159,0,0,0-.171.153,1.246,1.246,0,0,0,.255.816.745.745,0,0,0,.544.241h.033a.99.99,0,0,0,.745-.378,1.68,1.68,0,0,0,.372-.833,1.841,1.841,0,0,1-.779,1.459.811.811,0,0,1-.814.059.7.7,0,0,1-.357-.687.162.162,0,0,0-.322-.04,1.02,1.02,0,0,0,.536,1.019,1.1,1.1,0,0,0,.492.117,1.2,1.2,0,0,0,.637-.192,2.16,2.16,0,0,0,.933-1.736C118.643,168.892,118.641,168.778,118.638,168.658Zm-.979.167c-.029-.019-.006-.081,0-.093a.276.276,0,0,1,.227-.16h.013c.021,0,.076.007.1.13,0,.013,0,.026.006.04C117.822,168.849,117.7,168.853,117.658,168.825Z" transform="translate(-115.349 -165.738)" fill="#f5f5f5"/>
                          <path id="Path_11566" data-name="Path 11566" d="M101.84,243.306a.163.163,0,0,0-.222.058.334.334,0,0,1-.027.039l-.005-.013a1.093,1.093,0,0,1,0-.551.162.162,0,0,0-.314-.084,1.4,1.4,0,0,0,0,.749c.075.2.189.249.272.256h.022a.427.427,0,0,0,.324-.232A.162.162,0,0,0,101.84,243.306Z" transform="translate(-101.226 -238.725)" fill="#f5f5f5"/>
                          <path id="Path_11567" data-name="Path 11567" d="M175.292,164.057a.161.161,0,0,0,.1-.035l.819-.643a.156.156,0,0,0,.027-.031.407.407,0,1,0-.467-.036l-.58.455a.162.162,0,0,0,.1.29Zm.738-1.137a.081.081,0,1,1-.081.081A.081.081,0,0,1,176.03,162.92Z" transform="translate(-171.68 -162.422)" fill="#f5f5f5"/>
                          <path id="Path_11568" data-name="Path 11568" d="M185.389,190.441a.162.162,0,0,0-.206.1.5.5,0,0,0,.063.479.328.328,0,0,0,.243.093.714.714,0,0,0,.224-.041.162.162,0,1,0-.1-.308.563.563,0,0,1-.133.028.311.311,0,0,1,.013-.148A.162.162,0,0,0,185.389,190.441Z" transform="translate(-181.231 -188.96)" fill="#f5f5f5"/>
                          <path id="Path_11569" data-name="Path 11569" d="M227.733,163.091a.163.163,0,0,0,.2-.11l.095-.325a.162.162,0,0,0-.312-.091l-.095.325A.162.162,0,0,0,227.733,163.091Z" transform="translate(-221.716 -162.282)" fill="#f5f5f5"/>
                          <path id="Path_11570" data-name="Path 11570" d="M205.979,159.688a.162.162,0,1,0,.164-.28c-.033-.019-.028-.073-.027-.083a.127.127,0,0,1,.075-.108.162.162,0,0,0-.1-.31.446.446,0,0,0-.3.382A.4.4,0,0,0,205.979,159.688Z" transform="translate(-200.909 -158.898)" fill="#f5f5f5"/>
                          <path id="Path_11571" data-name="Path 11571" d="M178.916,232.19a.162.162,0,0,0-.03.322.305.305,0,0,1,.03.165.162.162,0,0,0,.325,0C179.241,232.2,178.93,232.19,178.916,232.19Z" transform="translate(-175.135 -228.769)" fill="#f5f5f5"/>
                          <path id="Path_11572" data-name="Path 11572" d="M234.75,208.294a.373.373,0,0,0,.061,0,.555.555,0,0,0,.384-.192.162.162,0,0,0-.23-.23c-.1.1-.149.1-.163.1-.031,0-.059-.045-.065-.056a.162.162,0,0,0-.29.147A.439.439,0,0,0,234.75,208.294Z" transform="translate(-228.213 -205.545)" fill="#f5f5f5"/>
                          <path id="Path_11573" data-name="Path 11573" d="M293.9,190.933l0-.007a.162.162,0,0,0-.308.1.364.364,0,0,0,.264.253l.036,0a.4.4,0,0,0,.314-.217.162.162,0,0,0-.27-.18A.5.5,0,0,1,293.9,190.933Z" transform="translate(-284.606 -189.326)" fill="#f5f5f5"/>
                          <path id="Path_11574" data-name="Path 11574" d="M204.018,170.29c0-.693-.03-1.342-.073-1.912a8.224,8.224,0,0,1,.57,1.157.162.162,0,1,0,.3-.114,10.757,10.757,0,0,0-.939-1.78c-.089-.875-.188-1.43-.19-1.44a.162.162,0,0,0-.32.058c0,.007.052.293.114.775-.125-.185-.241-.352-.338-.49l.008,0a.162.162,0,1,0,.1-.308l-.487-.162a.162.162,0,0,0-.183.059.164.164,0,0,0-.024.05.354.354,0,0,0-.339-.081.276.276,0,0,0-.155.289,1.468,1.468,0,0,0,.112.46c.1.271.156.47.054.565a.178.178,0,0,1-.166.059c-.1-.025-.2-.159-.265-.357a.152.152,0,0,0-.013-.023,2.762,2.762,0,0,0-.05-.9.162.162,0,0,0-.319.064,1.546,1.546,0,0,1-.085,1.158.292.292,0,0,1-.243.11.093.093,0,0,1-.078-.036,1.566,1.566,0,0,1-.086-.916.163.163,0,0,0-.161-.185c-.279,0-.385.318-.479.6-.041.123-.117.352-.171.376-.1,0-.162-.289-.162-.487a.162.162,0,0,0-.162-.162h0a.162.162,0,0,0-.162.162c0,.375.128.812.487.812.268,0,.376-.292.467-.563a1.018,1.018,0,0,0,.184.577.417.417,0,0,0,.323.148.618.618,0,0,0,.494-.229.747.747,0,0,0,.06-.09.606.606,0,0,0,.34.255.468.468,0,0,0,.115.015.515.515,0,0,0,.354-.151.482.482,0,0,0,.054-.066.439.439,0,0,0,.12.018.657.657,0,0,0,.337-.1.162.162,0,0,0-.174-.274.345.345,0,0,1-.184.048,1.676,1.676,0,0,0-.123-.534,2.252,2.252,0,0,1-.084-.272l.038.034a.162.162,0,0,0,.23,0,.157.157,0,0,0,.024-.036c.168.232.515.719.871,1.281.068.691.128,1.572.128,2.538a.162.162,0,0,0,.325,0Z" transform="translate(-195.038 -165.732)" fill="#f5f5f5"/>
                          <path id="Path_11575" data-name="Path 11575" d="M293.749,166.726a.162.162,0,1,0,.325,0,1.656,1.656,0,0,0-.18-.56.162.162,0,1,0-.291.145A1.443,1.443,0,0,1,293.749,166.726Z" transform="translate(-284.607 -165.741)" fill="#f5f5f5"/>
                          <path id="Path_11576" data-name="Path 11576" d="M263.156,249.931a.162.162,0,0,0-.218.072.465.465,0,0,1-.067.1.907.907,0,0,1,.043-.3.163.163,0,0,0-.27-.163l-.325.325a.162.162,0,1,0,.23.23l.008-.008a.336.336,0,0,0,.087.171.3.3,0,0,0,.211.093.289.289,0,0,0,.045,0,.529.529,0,0,0,.329-.294A.162.162,0,0,0,263.156,249.931Z" transform="translate(-254.753 -245.355)" fill="#f5f5f5"/>
                          <path id="Path_11577" data-name="Path 11577" d="M170.2,205.5h-1.145q.015-.061.031-.116c.457-.095.728-.242.808-.439a.363.363,0,0,0-.061-.365.4.4,0,0,0-.411-.175c-.231.047-.436.3-.584.694a1.868,1.868,0,0,0-.938.406,2.516,2.516,0,0,0-.337-1.08.162.162,0,0,0-.27.18,2.346,2.346,0,0,1,.3,1.209,1.285,1.285,0,0,1-.975,1.3c-.182,0-.262-.217-.3-.4a1.865,1.865,0,0,0,.78-.86,1.292,1.292,0,0,0-.108-.881l-.012-.028.01.01a.162.162,0,0,0,.23-.23c-.095-.1-.279-.244-.447-.174a.276.276,0,0,0-.155.289.691.691,0,0,0,.076.261.982.982,0,0,1,.092.674,1.374,1.374,0,0,1-.532.586.16.16,0,0,0-.123-.06h0a.162.162,0,0,0-.162.162c0,.029,0,.06,0,.093a8.541,8.541,0,0,1-1.045.572.162.162,0,0,0,.067.31.16.16,0,0,0,.067-.015,9.353,9.353,0,0,0,.97-.519.632.632,0,0,0,.587.534,1.38,1.38,0,0,0,1.087-.794.942.942,0,0,0,.108.155,1.831,1.831,0,0,0,1.241.477.162.162,0,0,0,.012-.324.348.348,0,0,1-.2-.1.279.279,0,0,1-.053-.213c.017-.2.662-.35,1.231-.359a.163.163,0,0,0,.151-.108l.162-.457a.163.163,0,0,0-.153-.217Zm-.713-.776.012,0c.016,0,.04.008.073.052.006.008.026.036.021.049-.019.046-.131.122-.364.191C169.333,204.809,169.434,204.731,169.489,204.72Zm-.981,2.135a1,1,0,0,1-.45-.273.691.691,0,0,1-.144-.572.477.477,0,0,1,.034-.069c.229-.021.84.069.882.244a.545.545,0,0,0-.343.424A.685.685,0,0,0,168.508,206.855Z" transform="translate(-161.862 -202.227)" fill="#f5f5f5"/>
                          <path id="Path_11578" data-name="Path 11578" d="M291.4,166.669l-.162-.487a.162.162,0,0,0-.316.068c.112,1.121.324,3.307.324,3.557,0,.225-.4.668-1.047.992a.162.162,0,0,0,.145.291c.591-.3,1.227-.823,1.227-1.282,0-.24-.166-1.967-.261-2.937A.163.163,0,0,0,291.4,166.669Z" transform="translate(-281.29 -165.736)" fill="#f5f5f5"/>
                          <path id="Path_11579" data-name="Path 11579" d="M335.881,194.018a.162.162,0,0,0,.272-.155.82.82,0,0,1-.023-.315.165.165,0,0,0,.169-.139.162.162,0,0,0-.138-.184.293.293,0,0,0-.268.09.372.372,0,0,0-.082.174l-.188-.188a.162.162,0,0,0-.23.23Z" transform="translate(-324.417 -191.619)" fill="#f5f5f5"/>
                          <path id="Path_11580" data-name="Path 11580" d="M340.806,213.295l-.609.437a.162.162,0,1,0,.189.264l.609-.437a.162.162,0,1,0-.189-.264Z" transform="translate(-328.977 -210.726)" fill="#f5f5f5"/>
                          <path id="Path_11581" data-name="Path 11581" d="M352.776,225.725a.162.162,0,0,0,.014.215.45.45,0,0,1,.033.234.162.162,0,0,0,.157.168h.006a.162.162,0,0,0,.162-.157c.012-.332-.1-.453-.144-.489A.162.162,0,0,0,352.776,225.725Z" transform="translate(-341.002 -222.546)" fill="#f5f5f5"/>
                          <path id="Path_11582" data-name="Path 11582" d="M324.335,170.117a.162.162,0,0,0,.162-.162c0-.24-.012-.507-.032-.786a5.925,5.925,0,0,1,.381.707.162.162,0,0,0,.149.1.16.16,0,0,0,.064-.013.162.162,0,0,0,.085-.213,8.935,8.935,0,0,0-.743-1.281c-.064-.6-.147-1.18-.212-1.6a.157.157,0,0,0,.1-.025.16.16,0,0,0,.072-.147.159.159,0,0,0,.018.047.591.591,0,0,1,.059.4.162.162,0,0,0,.126.192.171.171,0,0,0,.033,0,.163.163,0,0,0,.159-.13.921.921,0,0,0-.1-.638.163.163,0,0,0-.224-.05.161.161,0,0,0-.073.147.157.157,0,0,0-.018-.047l-.274-.426a.162.162,0,0,0-.3.116c0,.013.126.726.236,1.585-.125-.176-.249-.348-.37-.512-.02-.246-.039-.473-.056-.661l.026-.01a.162.162,0,0,0,.073-.218l-.162-.325a.162.162,0,0,0-.307.089c.019.194.04.408.059.634-.1-.135-.2-.259-.279-.364a.163.163,0,0,0-.033-.269l-.325-.162a.162.162,0,0,0-.2.247c.009.011.409.512.887,1.159a13.062,13.062,0,0,1,.06,2.424c-.014.043-.039.093-.063.1-.1.014-.333-.206-.45-.441a.226.226,0,0,0-.218-.147c-.175.011-.243.2-.366.552a2.287,2.287,0,0,1-.323.688,1.845,1.845,0,0,1-.2-.64.162.162,0,0,0,.314-.055c0-.242-.169-2.087-.264-3.1a.162.162,0,0,0,.093-.2l-.162-.487a.162.162,0,0,0-.316.067c.11,1.159.317,3.4.324,3.706v-.008a.162.162,0,1,0-.322.046c.047.332.169.9.448,1a.271.271,0,0,0,.087.014.319.319,0,0,0,.229-.106,2.343,2.343,0,0,0,.4-.812c.024-.067.052-.147.079-.215.149.218.409.489.672.467a.4.4,0,0,0,.348-.316A9.146,9.146,0,0,0,323.68,168c.137.192.274.388.4.582.051.486.087.974.087,1.374A.163.163,0,0,0,324.335,170.117Z" transform="translate(-311.142 -165.742)" fill="#f5f5f5"/>
                          <path id="Path_11583" data-name="Path 11583" d="M382.517,169.675c-.115-.857-.275-1.85-.377-2.46a.16.16,0,0,0,.136-.029.162.162,0,0,0,.031-.228l-.294-.386a.162.162,0,0,0-.289.126c0,.017.293,1.684.472,3.019a1.522,1.522,0,0,1-.267,1.219.718.718,0,0,1-.6.222.162.162,0,1,0-.02.324l.081,0a1.025,1.025,0,0,0,.773-.327A1.814,1.814,0,0,0,382.517,169.675Z" transform="translate(-368.092 -166.154)" fill="#f5f5f5"/>
                          <path id="Path_11584" data-name="Path 11584" d="M371.057,256.184a.165.165,0,0,0,.008.228.069.069,0,0,1,.019.034.076.076,0,0,1-.025.036.162.162,0,1,0,.23.23.369.369,0,0,0,.12-.277.387.387,0,0,0-.127-.265A.161.161,0,0,0,371.057,256.184Z" transform="translate(-358.419 -251.592)" fill="#f5f5f5"/>
                          <path id="Path_11585" data-name="Path 11585" d="M154.028,326.946h-1.137v-.162a.162.162,0,1,0-.325,0v.162h-8.609a2.268,2.268,0,0,0,1.462.487h7.147v.325a.162.162,0,0,0,.162.162h1.3a.487.487,0,1,0,0-.975Zm0,.65h-1.137v-.162h1.3l-.029-.087a.158.158,0,0,1,.029.087A.163.163,0,0,1,154.028,327.6Z" transform="translate(-141.962 -318.792)" fill="#f5f5f5"/>
                      </g>
                      </g>
                  </svg>                      
                  العربية
              </a>
            </div>

          </div>
      </div>
      
      <button (clickOutside)="onClickedOut($event)" (click)="clickinside()" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
    
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item active">
            <a class="nav-link" routerLink="/home">{{ 'Home' | translate }}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/About">{{ 'AboutUs' | translate }}</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" routerLink="/Serives">{{ 'Serives' | translate }}</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" routerLink="/FAQs">{{ 'FAQs' | translate }}</a>
            </li>
            <li class="nav-item logo-mobile">
              <a class="nav-link">
                <a class="dropdown-toggle language flagLang" type="button" data-toggle="dropdown" aria-expanded="false">
                  <svg *ngIf="flag" xmlns="http://www.w3.org/2000/svg" width="23.9" height="15.659" viewBox="0 0 23.9 15.659">
                    <g id="saudi-arabia" transform="translate(0 -88.276)">
                      <path id="Path_11560" data-name="Path 11560" d="M23.488,103.935H.412A.412.412,0,0,1,0,103.523V88.688a.412.412,0,0,1,.412-.412H23.488a.412.412,0,0,1,.412.412v14.834A.412.412,0,0,1,23.488,103.935Z" fill="#73af00"/>
                      <g id="Group_1818" data-name="Group 1818" transform="translate(4.725 91.573)">
                        <path id="Path_11561" data-name="Path 11561" d="M102.375,167.76c.041.26.2.342.335.331a.336.336,0,0,0,.3-.316v-.893a.081.081,0,1,1,.162,0v.9a.325.325,0,0,0,.446.3l.081-.032c.007.75-.038,1.409-.039,1.421a.162.162,0,0,0,.15.174h.012a.162.162,0,0,0,.162-.151c0-.053.054-.775.039-1.574l.049-.02a.163.163,0,0,1,.133.006.464.464,0,0,0,.536-.087l.029-.029a.485.485,0,0,0,.118-.5l-.138-.415a.162.162,0,0,0,.12-.3l-.325-.162a.162.162,0,0,0-.227.2l.261.784a.162.162,0,0,1-.039.166l-.029.029a.14.14,0,0,1-.161.026.486.486,0,0,0-.338-.034,5.6,5.6,0,0,0-.2-1.4.162.162,0,0,0-.308.1,5.584,5.584,0,0,1,.186,1.418l-.194.078v-.9a.406.406,0,0,0-.812,0v.786l-.166-.819a.162.162,0,1,0-.318.064Z" transform="translate(-102.155 -165.735)" fill="#f5f5f5"/>
                        <path id="Path_11562" data-name="Path 11562" d="M109.322,163.081a.162.162,0,0,0,.162-.162v-.162a.162.162,0,1,0-.325,0v.162A.162.162,0,0,0,109.322,163.081Z" transform="translate(-108.79 -162.422)" fill="#f5f5f5"/>
                        <path id="Path_11563" data-name="Path 11563" d="M144.119,162.919h.162a.162.162,0,1,0,0-.325h-.162a.162.162,0,1,0,0,.325Z" transform="translate(-141.962 -162.422)" fill="#f5f5f5"/>
                        <path id="Path_11564" data-name="Path 11564" d="M106.57,214.813l-.812.487a.162.162,0,1,0,.167.279l.812-.487a.162.162,0,1,0-.167-.279Z" transform="translate(-105.471 -212.181)" fill="#f5f5f5"/>
                        <path id="Path_11565" data-name="Path 11565" d="M118.638,168.658l0,0a.371.371,0,0,0,.075.153.319.319,0,0,0,.277.108.448.448,0,0,0,.338-.178.293.293,0,0,0,.034.02.307.307,0,0,0,.286-.009.617.617,0,0,0,.291-.572.166.166,0,0,0-.169-.154.163.163,0,0,0-.155.169s0,.195-.114.266a.4.4,0,0,1-.051-.137.162.162,0,0,0-.323.027c0,.024-.005.232-.166.25a.4.4,0,0,1,0-.213.162.162,0,0,0-.249-.172l-.091.062c-.043-.942-.142-2.04-.143-2.054a.162.162,0,1,0-.324.03c0,.015.116,1.285.151,2.255l-.025.024a.419.419,0,0,0-.356-.279.593.593,0,0,0-.572.362.384.384,0,0,0,.124.484.541.541,0,0,0,.484.022,1.358,1.358,0,0,1-.264.5.682.682,0,0,1-.519.264.427.427,0,0,1-.315-.135.928.928,0,0,1-.172-.581.162.162,0,0,0-.153-.171.159.159,0,0,0-.171.153,1.246,1.246,0,0,0,.255.816.745.745,0,0,0,.544.241h.033a.99.99,0,0,0,.745-.378,1.68,1.68,0,0,0,.372-.833,1.841,1.841,0,0,1-.779,1.459.811.811,0,0,1-.814.059.7.7,0,0,1-.357-.687.162.162,0,0,0-.322-.04,1.02,1.02,0,0,0,.536,1.019,1.1,1.1,0,0,0,.492.117,1.2,1.2,0,0,0,.637-.192,2.16,2.16,0,0,0,.933-1.736C118.643,168.892,118.641,168.778,118.638,168.658Zm-.979.167c-.029-.019-.006-.081,0-.093a.276.276,0,0,1,.227-.16h.013c.021,0,.076.007.1.13,0,.013,0,.026.006.04C117.822,168.849,117.7,168.853,117.658,168.825Z" transform="translate(-115.349 -165.738)" fill="#f5f5f5"/>
                        <path id="Path_11566" data-name="Path 11566" d="M101.84,243.306a.163.163,0,0,0-.222.058.334.334,0,0,1-.027.039l-.005-.013a1.093,1.093,0,0,1,0-.551.162.162,0,0,0-.314-.084,1.4,1.4,0,0,0,0,.749c.075.2.189.249.272.256h.022a.427.427,0,0,0,.324-.232A.162.162,0,0,0,101.84,243.306Z" transform="translate(-101.226 -238.725)" fill="#f5f5f5"/>
                        <path id="Path_11567" data-name="Path 11567" d="M175.292,164.057a.161.161,0,0,0,.1-.035l.819-.643a.156.156,0,0,0,.027-.031.407.407,0,1,0-.467-.036l-.58.455a.162.162,0,0,0,.1.29Zm.738-1.137a.081.081,0,1,1-.081.081A.081.081,0,0,1,176.03,162.92Z" transform="translate(-171.68 -162.422)" fill="#f5f5f5"/>
                        <path id="Path_11568" data-name="Path 11568" d="M185.389,190.441a.162.162,0,0,0-.206.1.5.5,0,0,0,.063.479.328.328,0,0,0,.243.093.714.714,0,0,0,.224-.041.162.162,0,1,0-.1-.308.563.563,0,0,1-.133.028.311.311,0,0,1,.013-.148A.162.162,0,0,0,185.389,190.441Z" transform="translate(-181.231 -188.96)" fill="#f5f5f5"/>
                        <path id="Path_11569" data-name="Path 11569" d="M227.733,163.091a.163.163,0,0,0,.2-.11l.095-.325a.162.162,0,0,0-.312-.091l-.095.325A.162.162,0,0,0,227.733,163.091Z" transform="translate(-221.716 -162.282)" fill="#f5f5f5"/>
                        <path id="Path_11570" data-name="Path 11570" d="M205.979,159.688a.162.162,0,1,0,.164-.28c-.033-.019-.028-.073-.027-.083a.127.127,0,0,1,.075-.108.162.162,0,0,0-.1-.31.446.446,0,0,0-.3.382A.4.4,0,0,0,205.979,159.688Z" transform="translate(-200.909 -158.898)" fill="#f5f5f5"/>
                        <path id="Path_11571" data-name="Path 11571" d="M178.916,232.19a.162.162,0,0,0-.03.322.305.305,0,0,1,.03.165.162.162,0,0,0,.325,0C179.241,232.2,178.93,232.19,178.916,232.19Z" transform="translate(-175.135 -228.769)" fill="#f5f5f5"/>
                        <path id="Path_11572" data-name="Path 11572" d="M234.75,208.294a.373.373,0,0,0,.061,0,.555.555,0,0,0,.384-.192.162.162,0,0,0-.23-.23c-.1.1-.149.1-.163.1-.031,0-.059-.045-.065-.056a.162.162,0,0,0-.29.147A.439.439,0,0,0,234.75,208.294Z" transform="translate(-228.213 -205.545)" fill="#f5f5f5"/>
                        <path id="Path_11573" data-name="Path 11573" d="M293.9,190.933l0-.007a.162.162,0,0,0-.308.1.364.364,0,0,0,.264.253l.036,0a.4.4,0,0,0,.314-.217.162.162,0,0,0-.27-.18A.5.5,0,0,1,293.9,190.933Z" transform="translate(-284.606 -189.326)" fill="#f5f5f5"/>
                        <path id="Path_11574" data-name="Path 11574" d="M204.018,170.29c0-.693-.03-1.342-.073-1.912a8.224,8.224,0,0,1,.57,1.157.162.162,0,1,0,.3-.114,10.757,10.757,0,0,0-.939-1.78c-.089-.875-.188-1.43-.19-1.44a.162.162,0,0,0-.32.058c0,.007.052.293.114.775-.125-.185-.241-.352-.338-.49l.008,0a.162.162,0,1,0,.1-.308l-.487-.162a.162.162,0,0,0-.183.059.164.164,0,0,0-.024.05.354.354,0,0,0-.339-.081.276.276,0,0,0-.155.289,1.468,1.468,0,0,0,.112.46c.1.271.156.47.054.565a.178.178,0,0,1-.166.059c-.1-.025-.2-.159-.265-.357a.152.152,0,0,0-.013-.023,2.762,2.762,0,0,0-.05-.9.162.162,0,0,0-.319.064,1.546,1.546,0,0,1-.085,1.158.292.292,0,0,1-.243.11.093.093,0,0,1-.078-.036,1.566,1.566,0,0,1-.086-.916.163.163,0,0,0-.161-.185c-.279,0-.385.318-.479.6-.041.123-.117.352-.171.376-.1,0-.162-.289-.162-.487a.162.162,0,0,0-.162-.162h0a.162.162,0,0,0-.162.162c0,.375.128.812.487.812.268,0,.376-.292.467-.563a1.018,1.018,0,0,0,.184.577.417.417,0,0,0,.323.148.618.618,0,0,0,.494-.229.747.747,0,0,0,.06-.09.606.606,0,0,0,.34.255.468.468,0,0,0,.115.015.515.515,0,0,0,.354-.151.482.482,0,0,0,.054-.066.439.439,0,0,0,.12.018.657.657,0,0,0,.337-.1.162.162,0,0,0-.174-.274.345.345,0,0,1-.184.048,1.676,1.676,0,0,0-.123-.534,2.252,2.252,0,0,1-.084-.272l.038.034a.162.162,0,0,0,.23,0,.157.157,0,0,0,.024-.036c.168.232.515.719.871,1.281.068.691.128,1.572.128,2.538a.162.162,0,0,0,.325,0Z" transform="translate(-195.038 -165.732)" fill="#f5f5f5"/>
                        <path id="Path_11575" data-name="Path 11575" d="M293.749,166.726a.162.162,0,1,0,.325,0,1.656,1.656,0,0,0-.18-.56.162.162,0,1,0-.291.145A1.443,1.443,0,0,1,293.749,166.726Z" transform="translate(-284.607 -165.741)" fill="#f5f5f5"/>
                        <path id="Path_11576" data-name="Path 11576" d="M263.156,249.931a.162.162,0,0,0-.218.072.465.465,0,0,1-.067.1.907.907,0,0,1,.043-.3.163.163,0,0,0-.27-.163l-.325.325a.162.162,0,1,0,.23.23l.008-.008a.336.336,0,0,0,.087.171.3.3,0,0,0,.211.093.289.289,0,0,0,.045,0,.529.529,0,0,0,.329-.294A.162.162,0,0,0,263.156,249.931Z" transform="translate(-254.753 -245.355)" fill="#f5f5f5"/>
                        <path id="Path_11577" data-name="Path 11577" d="M170.2,205.5h-1.145q.015-.061.031-.116c.457-.095.728-.242.808-.439a.363.363,0,0,0-.061-.365.4.4,0,0,0-.411-.175c-.231.047-.436.3-.584.694a1.868,1.868,0,0,0-.938.406,2.516,2.516,0,0,0-.337-1.08.162.162,0,0,0-.27.18,2.346,2.346,0,0,1,.3,1.209,1.285,1.285,0,0,1-.975,1.3c-.182,0-.262-.217-.3-.4a1.865,1.865,0,0,0,.78-.86,1.292,1.292,0,0,0-.108-.881l-.012-.028.01.01a.162.162,0,0,0,.23-.23c-.095-.1-.279-.244-.447-.174a.276.276,0,0,0-.155.289.691.691,0,0,0,.076.261.982.982,0,0,1,.092.674,1.374,1.374,0,0,1-.532.586.16.16,0,0,0-.123-.06h0a.162.162,0,0,0-.162.162c0,.029,0,.06,0,.093a8.541,8.541,0,0,1-1.045.572.162.162,0,0,0,.067.31.16.16,0,0,0,.067-.015,9.353,9.353,0,0,0,.97-.519.632.632,0,0,0,.587.534,1.38,1.38,0,0,0,1.087-.794.942.942,0,0,0,.108.155,1.831,1.831,0,0,0,1.241.477.162.162,0,0,0,.012-.324.348.348,0,0,1-.2-.1.279.279,0,0,1-.053-.213c.017-.2.662-.35,1.231-.359a.163.163,0,0,0,.151-.108l.162-.457a.163.163,0,0,0-.153-.217Zm-.713-.776.012,0c.016,0,.04.008.073.052.006.008.026.036.021.049-.019.046-.131.122-.364.191C169.333,204.809,169.434,204.731,169.489,204.72Zm-.981,2.135a1,1,0,0,1-.45-.273.691.691,0,0,1-.144-.572.477.477,0,0,1,.034-.069c.229-.021.84.069.882.244a.545.545,0,0,0-.343.424A.685.685,0,0,0,168.508,206.855Z" transform="translate(-161.862 -202.227)" fill="#f5f5f5"/>
                        <path id="Path_11578" data-name="Path 11578" d="M291.4,166.669l-.162-.487a.162.162,0,0,0-.316.068c.112,1.121.324,3.307.324,3.557,0,.225-.4.668-1.047.992a.162.162,0,0,0,.145.291c.591-.3,1.227-.823,1.227-1.282,0-.24-.166-1.967-.261-2.937A.163.163,0,0,0,291.4,166.669Z" transform="translate(-281.29 -165.736)" fill="#f5f5f5"/>
                        <path id="Path_11579" data-name="Path 11579" d="M335.881,194.018a.162.162,0,0,0,.272-.155.82.82,0,0,1-.023-.315.165.165,0,0,0,.169-.139.162.162,0,0,0-.138-.184.293.293,0,0,0-.268.09.372.372,0,0,0-.082.174l-.188-.188a.162.162,0,0,0-.23.23Z" transform="translate(-324.417 -191.619)" fill="#f5f5f5"/>
                        <path id="Path_11580" data-name="Path 11580" d="M340.806,213.295l-.609.437a.162.162,0,1,0,.189.264l.609-.437a.162.162,0,1,0-.189-.264Z" transform="translate(-328.977 -210.726)" fill="#f5f5f5"/>
                        <path id="Path_11581" data-name="Path 11581" d="M352.776,225.725a.162.162,0,0,0,.014.215.45.45,0,0,1,.033.234.162.162,0,0,0,.157.168h.006a.162.162,0,0,0,.162-.157c.012-.332-.1-.453-.144-.489A.162.162,0,0,0,352.776,225.725Z" transform="translate(-341.002 -222.546)" fill="#f5f5f5"/>
                        <path id="Path_11582" data-name="Path 11582" d="M324.335,170.117a.162.162,0,0,0,.162-.162c0-.24-.012-.507-.032-.786a5.925,5.925,0,0,1,.381.707.162.162,0,0,0,.149.1.16.16,0,0,0,.064-.013.162.162,0,0,0,.085-.213,8.935,8.935,0,0,0-.743-1.281c-.064-.6-.147-1.18-.212-1.6a.157.157,0,0,0,.1-.025.16.16,0,0,0,.072-.147.159.159,0,0,0,.018.047.591.591,0,0,1,.059.4.162.162,0,0,0,.126.192.171.171,0,0,0,.033,0,.163.163,0,0,0,.159-.13.921.921,0,0,0-.1-.638.163.163,0,0,0-.224-.05.161.161,0,0,0-.073.147.157.157,0,0,0-.018-.047l-.274-.426a.162.162,0,0,0-.3.116c0,.013.126.726.236,1.585-.125-.176-.249-.348-.37-.512-.02-.246-.039-.473-.056-.661l.026-.01a.162.162,0,0,0,.073-.218l-.162-.325a.162.162,0,0,0-.307.089c.019.194.04.408.059.634-.1-.135-.2-.259-.279-.364a.163.163,0,0,0-.033-.269l-.325-.162a.162.162,0,0,0-.2.247c.009.011.409.512.887,1.159a13.062,13.062,0,0,1,.06,2.424c-.014.043-.039.093-.063.1-.1.014-.333-.206-.45-.441a.226.226,0,0,0-.218-.147c-.175.011-.243.2-.366.552a2.287,2.287,0,0,1-.323.688,1.845,1.845,0,0,1-.2-.64.162.162,0,0,0,.314-.055c0-.242-.169-2.087-.264-3.1a.162.162,0,0,0,.093-.2l-.162-.487a.162.162,0,0,0-.316.067c.11,1.159.317,3.4.324,3.706v-.008a.162.162,0,1,0-.322.046c.047.332.169.9.448,1a.271.271,0,0,0,.087.014.319.319,0,0,0,.229-.106,2.343,2.343,0,0,0,.4-.812c.024-.067.052-.147.079-.215.149.218.409.489.672.467a.4.4,0,0,0,.348-.316A9.146,9.146,0,0,0,323.68,168c.137.192.274.388.4.582.051.486.087.974.087,1.374A.163.163,0,0,0,324.335,170.117Z" transform="translate(-311.142 -165.742)" fill="#f5f5f5"/>
                        <path id="Path_11583" data-name="Path 11583" d="M382.517,169.675c-.115-.857-.275-1.85-.377-2.46a.16.16,0,0,0,.136-.029.162.162,0,0,0,.031-.228l-.294-.386a.162.162,0,0,0-.289.126c0,.017.293,1.684.472,3.019a1.522,1.522,0,0,1-.267,1.219.718.718,0,0,1-.6.222.162.162,0,1,0-.02.324l.081,0a1.025,1.025,0,0,0,.773-.327A1.814,1.814,0,0,0,382.517,169.675Z" transform="translate(-368.092 -166.154)" fill="#f5f5f5"/>
                        <path id="Path_11584" data-name="Path 11584" d="M371.057,256.184a.165.165,0,0,0,.008.228.069.069,0,0,1,.019.034.076.076,0,0,1-.025.036.162.162,0,1,0,.23.23.369.369,0,0,0,.12-.277.387.387,0,0,0-.127-.265A.161.161,0,0,0,371.057,256.184Z" transform="translate(-358.419 -251.592)" fill="#f5f5f5"/>
                        <path id="Path_11585" data-name="Path 11585" d="M154.028,326.946h-1.137v-.162a.162.162,0,1,0-.325,0v.162h-8.609a2.268,2.268,0,0,0,1.462.487h7.147v.325a.162.162,0,0,0,.162.162h1.3a.487.487,0,1,0,0-.975Zm0,.65h-1.137v-.162h1.3l-.029-.087a.158.158,0,0,1,.029.087A.163.163,0,0,1,154.028,327.6Z" transform="translate(-141.962 -318.792)" fill="#f5f5f5"/>
                      </g>
                    </g>
                  </svg>     
                  <img class="flaghome" *ngIf="!flag" src="../../../../assets/image/Flag_of_England.svg.png">                  
                 {{titleLang}}
                </a>
                <div class="dropdown-menu">
                  <a class="dropdown-item flagLang" (click)="langButton('en')">
                      <img class="flaghome" src="../../../../assets/image/Flag_of_England.svg.png">                      
                      English
                  </a>
                  <a class="dropdown-item flagLang" (click)="langButton('ar')">
                      <svg xmlns="http://www.w3.org/2000/svg" width="23.9" height="15.659" viewBox="0 0 23.9 15.659">
                          <g id="saudi-arabia" transform="translate(0 -88.276)">
                          <path id="Path_11560" data-name="Path 11560" d="M23.488,103.935H.412A.412.412,0,0,1,0,103.523V88.688a.412.412,0,0,1,.412-.412H23.488a.412.412,0,0,1,.412.412v14.834A.412.412,0,0,1,23.488,103.935Z" fill="#73af00"/>
                          <g id="Group_1818" data-name="Group 1818" transform="translate(4.725 91.573)">
                              <path id="Path_11561" data-name="Path 11561" d="M102.375,167.76c.041.26.2.342.335.331a.336.336,0,0,0,.3-.316v-.893a.081.081,0,1,1,.162,0v.9a.325.325,0,0,0,.446.3l.081-.032c.007.75-.038,1.409-.039,1.421a.162.162,0,0,0,.15.174h.012a.162.162,0,0,0,.162-.151c0-.053.054-.775.039-1.574l.049-.02a.163.163,0,0,1,.133.006.464.464,0,0,0,.536-.087l.029-.029a.485.485,0,0,0,.118-.5l-.138-.415a.162.162,0,0,0,.12-.3l-.325-.162a.162.162,0,0,0-.227.2l.261.784a.162.162,0,0,1-.039.166l-.029.029a.14.14,0,0,1-.161.026.486.486,0,0,0-.338-.034,5.6,5.6,0,0,0-.2-1.4.162.162,0,0,0-.308.1,5.584,5.584,0,0,1,.186,1.418l-.194.078v-.9a.406.406,0,0,0-.812,0v.786l-.166-.819a.162.162,0,1,0-.318.064Z" transform="translate(-102.155 -165.735)" fill="#f5f5f5"/>
                              <path id="Path_11562" data-name="Path 11562" d="M109.322,163.081a.162.162,0,0,0,.162-.162v-.162a.162.162,0,1,0-.325,0v.162A.162.162,0,0,0,109.322,163.081Z" transform="translate(-108.79 -162.422)" fill="#f5f5f5"/>
                              <path id="Path_11563" data-name="Path 11563" d="M144.119,162.919h.162a.162.162,0,1,0,0-.325h-.162a.162.162,0,1,0,0,.325Z" transform="translate(-141.962 -162.422)" fill="#f5f5f5"/>
                              <path id="Path_11564" data-name="Path 11564" d="M106.57,214.813l-.812.487a.162.162,0,1,0,.167.279l.812-.487a.162.162,0,1,0-.167-.279Z" transform="translate(-105.471 -212.181)" fill="#f5f5f5"/>
                              <path id="Path_11565" data-name="Path 11565" d="M118.638,168.658l0,0a.371.371,0,0,0,.075.153.319.319,0,0,0,.277.108.448.448,0,0,0,.338-.178.293.293,0,0,0,.034.02.307.307,0,0,0,.286-.009.617.617,0,0,0,.291-.572.166.166,0,0,0-.169-.154.163.163,0,0,0-.155.169s0,.195-.114.266a.4.4,0,0,1-.051-.137.162.162,0,0,0-.323.027c0,.024-.005.232-.166.25a.4.4,0,0,1,0-.213.162.162,0,0,0-.249-.172l-.091.062c-.043-.942-.142-2.04-.143-2.054a.162.162,0,1,0-.324.03c0,.015.116,1.285.151,2.255l-.025.024a.419.419,0,0,0-.356-.279.593.593,0,0,0-.572.362.384.384,0,0,0,.124.484.541.541,0,0,0,.484.022,1.358,1.358,0,0,1-.264.5.682.682,0,0,1-.519.264.427.427,0,0,1-.315-.135.928.928,0,0,1-.172-.581.162.162,0,0,0-.153-.171.159.159,0,0,0-.171.153,1.246,1.246,0,0,0,.255.816.745.745,0,0,0,.544.241h.033a.99.99,0,0,0,.745-.378,1.68,1.68,0,0,0,.372-.833,1.841,1.841,0,0,1-.779,1.459.811.811,0,0,1-.814.059.7.7,0,0,1-.357-.687.162.162,0,0,0-.322-.04,1.02,1.02,0,0,0,.536,1.019,1.1,1.1,0,0,0,.492.117,1.2,1.2,0,0,0,.637-.192,2.16,2.16,0,0,0,.933-1.736C118.643,168.892,118.641,168.778,118.638,168.658Zm-.979.167c-.029-.019-.006-.081,0-.093a.276.276,0,0,1,.227-.16h.013c.021,0,.076.007.1.13,0,.013,0,.026.006.04C117.822,168.849,117.7,168.853,117.658,168.825Z" transform="translate(-115.349 -165.738)" fill="#f5f5f5"/>
                              <path id="Path_11566" data-name="Path 11566" d="M101.84,243.306a.163.163,0,0,0-.222.058.334.334,0,0,1-.027.039l-.005-.013a1.093,1.093,0,0,1,0-.551.162.162,0,0,0-.314-.084,1.4,1.4,0,0,0,0,.749c.075.2.189.249.272.256h.022a.427.427,0,0,0,.324-.232A.162.162,0,0,0,101.84,243.306Z" transform="translate(-101.226 -238.725)" fill="#f5f5f5"/>
                              <path id="Path_11567" data-name="Path 11567" d="M175.292,164.057a.161.161,0,0,0,.1-.035l.819-.643a.156.156,0,0,0,.027-.031.407.407,0,1,0-.467-.036l-.58.455a.162.162,0,0,0,.1.29Zm.738-1.137a.081.081,0,1,1-.081.081A.081.081,0,0,1,176.03,162.92Z" transform="translate(-171.68 -162.422)" fill="#f5f5f5"/>
                              <path id="Path_11568" data-name="Path 11568" d="M185.389,190.441a.162.162,0,0,0-.206.1.5.5,0,0,0,.063.479.328.328,0,0,0,.243.093.714.714,0,0,0,.224-.041.162.162,0,1,0-.1-.308.563.563,0,0,1-.133.028.311.311,0,0,1,.013-.148A.162.162,0,0,0,185.389,190.441Z" transform="translate(-181.231 -188.96)" fill="#f5f5f5"/>
                              <path id="Path_11569" data-name="Path 11569" d="M227.733,163.091a.163.163,0,0,0,.2-.11l.095-.325a.162.162,0,0,0-.312-.091l-.095.325A.162.162,0,0,0,227.733,163.091Z" transform="translate(-221.716 -162.282)" fill="#f5f5f5"/>
                              <path id="Path_11570" data-name="Path 11570" d="M205.979,159.688a.162.162,0,1,0,.164-.28c-.033-.019-.028-.073-.027-.083a.127.127,0,0,1,.075-.108.162.162,0,0,0-.1-.31.446.446,0,0,0-.3.382A.4.4,0,0,0,205.979,159.688Z" transform="translate(-200.909 -158.898)" fill="#f5f5f5"/>
                              <path id="Path_11571" data-name="Path 11571" d="M178.916,232.19a.162.162,0,0,0-.03.322.305.305,0,0,1,.03.165.162.162,0,0,0,.325,0C179.241,232.2,178.93,232.19,178.916,232.19Z" transform="translate(-175.135 -228.769)" fill="#f5f5f5"/>
                              <path id="Path_11572" data-name="Path 11572" d="M234.75,208.294a.373.373,0,0,0,.061,0,.555.555,0,0,0,.384-.192.162.162,0,0,0-.23-.23c-.1.1-.149.1-.163.1-.031,0-.059-.045-.065-.056a.162.162,0,0,0-.29.147A.439.439,0,0,0,234.75,208.294Z" transform="translate(-228.213 -205.545)" fill="#f5f5f5"/>
                              <path id="Path_11573" data-name="Path 11573" d="M293.9,190.933l0-.007a.162.162,0,0,0-.308.1.364.364,0,0,0,.264.253l.036,0a.4.4,0,0,0,.314-.217.162.162,0,0,0-.27-.18A.5.5,0,0,1,293.9,190.933Z" transform="translate(-284.606 -189.326)" fill="#f5f5f5"/>
                              <path id="Path_11574" data-name="Path 11574" d="M204.018,170.29c0-.693-.03-1.342-.073-1.912a8.224,8.224,0,0,1,.57,1.157.162.162,0,1,0,.3-.114,10.757,10.757,0,0,0-.939-1.78c-.089-.875-.188-1.43-.19-1.44a.162.162,0,0,0-.32.058c0,.007.052.293.114.775-.125-.185-.241-.352-.338-.49l.008,0a.162.162,0,1,0,.1-.308l-.487-.162a.162.162,0,0,0-.183.059.164.164,0,0,0-.024.05.354.354,0,0,0-.339-.081.276.276,0,0,0-.155.289,1.468,1.468,0,0,0,.112.46c.1.271.156.47.054.565a.178.178,0,0,1-.166.059c-.1-.025-.2-.159-.265-.357a.152.152,0,0,0-.013-.023,2.762,2.762,0,0,0-.05-.9.162.162,0,0,0-.319.064,1.546,1.546,0,0,1-.085,1.158.292.292,0,0,1-.243.11.093.093,0,0,1-.078-.036,1.566,1.566,0,0,1-.086-.916.163.163,0,0,0-.161-.185c-.279,0-.385.318-.479.6-.041.123-.117.352-.171.376-.1,0-.162-.289-.162-.487a.162.162,0,0,0-.162-.162h0a.162.162,0,0,0-.162.162c0,.375.128.812.487.812.268,0,.376-.292.467-.563a1.018,1.018,0,0,0,.184.577.417.417,0,0,0,.323.148.618.618,0,0,0,.494-.229.747.747,0,0,0,.06-.09.606.606,0,0,0,.34.255.468.468,0,0,0,.115.015.515.515,0,0,0,.354-.151.482.482,0,0,0,.054-.066.439.439,0,0,0,.12.018.657.657,0,0,0,.337-.1.162.162,0,0,0-.174-.274.345.345,0,0,1-.184.048,1.676,1.676,0,0,0-.123-.534,2.252,2.252,0,0,1-.084-.272l.038.034a.162.162,0,0,0,.23,0,.157.157,0,0,0,.024-.036c.168.232.515.719.871,1.281.068.691.128,1.572.128,2.538a.162.162,0,0,0,.325,0Z" transform="translate(-195.038 -165.732)" fill="#f5f5f5"/>
                              <path id="Path_11575" data-name="Path 11575" d="M293.749,166.726a.162.162,0,1,0,.325,0,1.656,1.656,0,0,0-.18-.56.162.162,0,1,0-.291.145A1.443,1.443,0,0,1,293.749,166.726Z" transform="translate(-284.607 -165.741)" fill="#f5f5f5"/>
                              <path id="Path_11576" data-name="Path 11576" d="M263.156,249.931a.162.162,0,0,0-.218.072.465.465,0,0,1-.067.1.907.907,0,0,1,.043-.3.163.163,0,0,0-.27-.163l-.325.325a.162.162,0,1,0,.23.23l.008-.008a.336.336,0,0,0,.087.171.3.3,0,0,0,.211.093.289.289,0,0,0,.045,0,.529.529,0,0,0,.329-.294A.162.162,0,0,0,263.156,249.931Z" transform="translate(-254.753 -245.355)" fill="#f5f5f5"/>
                              <path id="Path_11577" data-name="Path 11577" d="M170.2,205.5h-1.145q.015-.061.031-.116c.457-.095.728-.242.808-.439a.363.363,0,0,0-.061-.365.4.4,0,0,0-.411-.175c-.231.047-.436.3-.584.694a1.868,1.868,0,0,0-.938.406,2.516,2.516,0,0,0-.337-1.08.162.162,0,0,0-.27.18,2.346,2.346,0,0,1,.3,1.209,1.285,1.285,0,0,1-.975,1.3c-.182,0-.262-.217-.3-.4a1.865,1.865,0,0,0,.78-.86,1.292,1.292,0,0,0-.108-.881l-.012-.028.01.01a.162.162,0,0,0,.23-.23c-.095-.1-.279-.244-.447-.174a.276.276,0,0,0-.155.289.691.691,0,0,0,.076.261.982.982,0,0,1,.092.674,1.374,1.374,0,0,1-.532.586.16.16,0,0,0-.123-.06h0a.162.162,0,0,0-.162.162c0,.029,0,.06,0,.093a8.541,8.541,0,0,1-1.045.572.162.162,0,0,0,.067.31.16.16,0,0,0,.067-.015,9.353,9.353,0,0,0,.97-.519.632.632,0,0,0,.587.534,1.38,1.38,0,0,0,1.087-.794.942.942,0,0,0,.108.155,1.831,1.831,0,0,0,1.241.477.162.162,0,0,0,.012-.324.348.348,0,0,1-.2-.1.279.279,0,0,1-.053-.213c.017-.2.662-.35,1.231-.359a.163.163,0,0,0,.151-.108l.162-.457a.163.163,0,0,0-.153-.217Zm-.713-.776.012,0c.016,0,.04.008.073.052.006.008.026.036.021.049-.019.046-.131.122-.364.191C169.333,204.809,169.434,204.731,169.489,204.72Zm-.981,2.135a1,1,0,0,1-.45-.273.691.691,0,0,1-.144-.572.477.477,0,0,1,.034-.069c.229-.021.84.069.882.244a.545.545,0,0,0-.343.424A.685.685,0,0,0,168.508,206.855Z" transform="translate(-161.862 -202.227)" fill="#f5f5f5"/>
                              <path id="Path_11578" data-name="Path 11578" d="M291.4,166.669l-.162-.487a.162.162,0,0,0-.316.068c.112,1.121.324,3.307.324,3.557,0,.225-.4.668-1.047.992a.162.162,0,0,0,.145.291c.591-.3,1.227-.823,1.227-1.282,0-.24-.166-1.967-.261-2.937A.163.163,0,0,0,291.4,166.669Z" transform="translate(-281.29 -165.736)" fill="#f5f5f5"/>
                              <path id="Path_11579" data-name="Path 11579" d="M335.881,194.018a.162.162,0,0,0,.272-.155.82.82,0,0,1-.023-.315.165.165,0,0,0,.169-.139.162.162,0,0,0-.138-.184.293.293,0,0,0-.268.09.372.372,0,0,0-.082.174l-.188-.188a.162.162,0,0,0-.23.23Z" transform="translate(-324.417 -191.619)" fill="#f5f5f5"/>
                              <path id="Path_11580" data-name="Path 11580" d="M340.806,213.295l-.609.437a.162.162,0,1,0,.189.264l.609-.437a.162.162,0,1,0-.189-.264Z" transform="translate(-328.977 -210.726)" fill="#f5f5f5"/>
                              <path id="Path_11581" data-name="Path 11581" d="M352.776,225.725a.162.162,0,0,0,.014.215.45.45,0,0,1,.033.234.162.162,0,0,0,.157.168h.006a.162.162,0,0,0,.162-.157c.012-.332-.1-.453-.144-.489A.162.162,0,0,0,352.776,225.725Z" transform="translate(-341.002 -222.546)" fill="#f5f5f5"/>
                              <path id="Path_11582" data-name="Path 11582" d="M324.335,170.117a.162.162,0,0,0,.162-.162c0-.24-.012-.507-.032-.786a5.925,5.925,0,0,1,.381.707.162.162,0,0,0,.149.1.16.16,0,0,0,.064-.013.162.162,0,0,0,.085-.213,8.935,8.935,0,0,0-.743-1.281c-.064-.6-.147-1.18-.212-1.6a.157.157,0,0,0,.1-.025.16.16,0,0,0,.072-.147.159.159,0,0,0,.018.047.591.591,0,0,1,.059.4.162.162,0,0,0,.126.192.171.171,0,0,0,.033,0,.163.163,0,0,0,.159-.13.921.921,0,0,0-.1-.638.163.163,0,0,0-.224-.05.161.161,0,0,0-.073.147.157.157,0,0,0-.018-.047l-.274-.426a.162.162,0,0,0-.3.116c0,.013.126.726.236,1.585-.125-.176-.249-.348-.37-.512-.02-.246-.039-.473-.056-.661l.026-.01a.162.162,0,0,0,.073-.218l-.162-.325a.162.162,0,0,0-.307.089c.019.194.04.408.059.634-.1-.135-.2-.259-.279-.364a.163.163,0,0,0-.033-.269l-.325-.162a.162.162,0,0,0-.2.247c.009.011.409.512.887,1.159a13.062,13.062,0,0,1,.06,2.424c-.014.043-.039.093-.063.1-.1.014-.333-.206-.45-.441a.226.226,0,0,0-.218-.147c-.175.011-.243.2-.366.552a2.287,2.287,0,0,1-.323.688,1.845,1.845,0,0,1-.2-.64.162.162,0,0,0,.314-.055c0-.242-.169-2.087-.264-3.1a.162.162,0,0,0,.093-.2l-.162-.487a.162.162,0,0,0-.316.067c.11,1.159.317,3.4.324,3.706v-.008a.162.162,0,1,0-.322.046c.047.332.169.9.448,1a.271.271,0,0,0,.087.014.319.319,0,0,0,.229-.106,2.343,2.343,0,0,0,.4-.812c.024-.067.052-.147.079-.215.149.218.409.489.672.467a.4.4,0,0,0,.348-.316A9.146,9.146,0,0,0,323.68,168c.137.192.274.388.4.582.051.486.087.974.087,1.374A.163.163,0,0,0,324.335,170.117Z" transform="translate(-311.142 -165.742)" fill="#f5f5f5"/>
                              <path id="Path_11583" data-name="Path 11583" d="M382.517,169.675c-.115-.857-.275-1.85-.377-2.46a.16.16,0,0,0,.136-.029.162.162,0,0,0,.031-.228l-.294-.386a.162.162,0,0,0-.289.126c0,.017.293,1.684.472,3.019a1.522,1.522,0,0,1-.267,1.219.718.718,0,0,1-.6.222.162.162,0,1,0-.02.324l.081,0a1.025,1.025,0,0,0,.773-.327A1.814,1.814,0,0,0,382.517,169.675Z" transform="translate(-368.092 -166.154)" fill="#f5f5f5"/>
                              <path id="Path_11584" data-name="Path 11584" d="M371.057,256.184a.165.165,0,0,0,.008.228.069.069,0,0,1,.019.034.076.076,0,0,1-.025.036.162.162,0,1,0,.23.23.369.369,0,0,0,.12-.277.387.387,0,0,0-.127-.265A.161.161,0,0,0,371.057,256.184Z" transform="translate(-358.419 -251.592)" fill="#f5f5f5"/>
                              <path id="Path_11585" data-name="Path 11585" d="M154.028,326.946h-1.137v-.162a.162.162,0,1,0-.325,0v.162h-8.609a2.268,2.268,0,0,0,1.462.487h7.147v.325a.162.162,0,0,0,.162.162h1.3a.487.487,0,1,0,0-.975Zm0,.65h-1.137v-.162h1.3l-.029-.087a.158.158,0,0,1,.029.087A.163.163,0,0,1,154.028,327.6Z" transform="translate(-141.962 -318.792)" fill="#f5f5f5"/>
                          </g>
                          </g>
                      </svg>                      
                      العربية
                  </a>
                </div>
              </a>
            </li>
        </ul>
        <form class="form-inline my-2 my-lg-0">
          <button class="btn btn-light my-2 my-sm-0 contactHeader" routerLink="/Contact-us">{{ 'ContactUs' | translate }}</button>
        </form>
      </div>
  </div>
</nav>