<section class="provide-home">
    <div class="container">
        <div class="row">
            <div class="col-md-1"></div>
            <div class="col-md-3 image-about">
                <img src="{{setting.imageAbout}}">
            </div>
            <div class="col-md-1"></div>
            <div class="col-md-7">
                <h1>{{ 'WeProvideServices' | translate }}</h1>
                <p> {{setting.about}} </p>
                <a class="btn btn-request" routerLink="/request-quotation">{{ 'RequestForService' | translate }}</a>
            </div>
        </div>
    </div>
</section>