<section class="workers asked-component">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h1 class="header-asked">{{ 'FrequentlyAskedQuestions' | translate }} </h1>
            </div>
        </div>
        <div class="accordion accordionAsk" id="accordionExample">
            <div class="card" *ngFor="let item of Fqa">
              <div class="card-header" id="heading{{item.id}}">
                <h2 class="mb-0">
                  <a class="header-accordion" data-toggle="collapse" [attr.data-target]="'#collapse'+ item.id" aria-expanded="true" aria-controls="collapseOne">
                    {{item.question}} 
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="13.585" viewBox="0 0 22 13.585">
                      <path id="ic_keyboard_arrow_down_24px" d="M8.585,7.84,17,16.237l8.415-8.4L28,10.425l-11,11-11-11Z" transform="translate(-6 -7.84)" fill="#5a5a5a"/>
                    </svg>
                    
                  </a>
                </h2>
              </div>
              <div id="collapse{{item.id}}" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                <div class="card-body">
                  {{item.answer}}
                </div>
              </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 seemore">
                <a class="btn btn-request" routerLink="/FAQs" >{{ 'SeeMore' | translate }}</a>
            </div>
        </div>
    </div>
</section>