<footer>
    <section class="bottom-footer">
        <div class="footer-posation">
            <div class="container">
                <div class="row">
                    <div class="col-md-2">
                        <img class="maroof-logo" src="../../../../assets/image/img/logofooter.png">
                    </div>
                    <div class="col-md-8">
                        <ul class="nav-footer">
                            <li>{{ 'AboutUs' | translate }}</li>
                            <li>{{ 'ContactUs' | translate }}</li>
                            <li> {{ 'PrivacyPolicy' | translate }}</li>
                            <li>{{ 'TermsConditions' | translate }}</li>
                            <li>{{ 'Regulations' | translate }}</li>
                        </ul>
                        <p class="copyright">{{ 'Copyright' | translate }}</p>
                    </div>
                    <div class="col-md-2">
                        <ul class="socialfooter">
                            <li>
                                <a target="_blank" href="{{footer?.socials?.facebook}}">
                                    <img width="14px" src="../../../../assets/image/icons/Facebook.png">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="{{footer?.socials?.twitter}}">
                                    <img width="20px" src="../../../../assets/image/icons/twitter.png">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="{{footer?.socials?.youtube}}">
                                    <img width="20px" src="../../../../assets/image/icons/Youtube.png">
                                </a>
                            </li>
                            <li>
                                <a target="_blank" href="{{footer?.socials?.instagram}}">
                                    <img width="20px" src="../../../../assets/image/icons/Instagram.png">
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</footer>